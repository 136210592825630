<template>
  <div>
    <TitleBarBaseVue />
    <el-tabs v-model="params.synchro" type="card" @tab-click="fecthList">
      <el-tab-pane :label="`全部商品(${tabProcess['allCount'] || 0})`" name="-1"></el-tab-pane>
      <el-tab-pane :label="`未添加(${tabProcess['notCount'] || 0})`" name="0"></el-tab-pane>
      <el-tab-pane :label="`已添加(${tabProcess['yesCount'] || 0})`" name="1"></el-tab-pane>
      <el-tab-pane :label="`回收站(${tabProcess['delCount'] || 0})`" name="2"></el-tab-pane>
    </el-tabs>
    <el-form :inline="true">
      <el-form-item>
        <el-select v-model="params.type" placeholder="请选择">
          <el-option label="搜商品" :value="1"> </el-option>
          <el-option label="搜店铺" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="params.keyWords" placeholder="请输入名称关键字" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fecthList">搜索</el-button>
        <el-button v-show="params.synchro == 1" :disabled="!selectChecked.length" @click="handleBatchRecall" type="danger">批量撤回</el-button>
      </el-form-item>
    </el-form>
    <!-- <TitleBarBaseVue>
      <div class="sort_wrap">
        <div class="sort_item">综合</div>
        <div class="sort_item">
          <span class="text">价格</span>
          <input class="price_input" type="text" placeholder="￥最低价" />
          —
          <input class="price_input" type="text" placeholder="￥最高价" />
        </div>
      </div>
    </TitleBarBaseVue> -->
    <div class="good_card_wrap" v-if="tableList.length">
      <div class="good_card" v-for="(item, index) in tableList" :key="index">
        <div class="card_title">
          <div class="left">
            <el-checkbox v-show="params.synchro == 1" v-model="item.checked" style="margin-right:10px"> </el-checkbox>
            <span :style="{ color: item.synchro == 0 ? '#FF0000' : '#E6A23C' }">{{ item.synchro == 0 ? '未上架' : item.synchro == 1 ? '已上架' : '已删除' }}</span>
          </div>
          <div class="right" v-show="item.synchro != 2">
            <el-link v-if="item.synchro == 0" :underline="false" type="primary" @click="handleSyncChro(item)">添加</el-link>
            <el-link v-else :underline="false" type="primary" @click="handleSyncChroRecal(item)">撤回</el-link>
          </div>
        </div>
        <el-tooltip content="点击查看详情" placement="top">
          <div class="card_image" @click="jumpUrl(item.aliUrl)">
            <img :src="item.smallImg" alt="" />
          </div>
        </el-tooltip>
        <div class="card_content ellipsis2">
          {{ item.name }}
        </div>
        <div class="card_price">
          <span>￥ {{ item.costPrice }}</span>
          <span v-show="item.synchro == 0" @click="handleGoods(item.goodsId)" class="delete">删除</span>
          <span v-show="item.synchro == 2" @click="handleGoods(item.goodsId)" class="reduction">还原</span>
        </div>
        <div class="card_business_name">{{ item.platformName }}</div>
      </div>
    </div>
    <div class="pageCo" v-if="total">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { authGoodsPage, authGoodsCount, authGoodsDown, authGoodsDownAll, authGoodsChange } from '@/api/AlibabaController'
export default {
  name: 'ordershenhe',
  components: {
    TitleBarBaseVue
  },
  inject: ['reload'],
  data() {
    return {
      tabProcess: {},
      checked: '',
      params: {
        pageNo: 1,
        pageSize: 10,
        keyWords: '',
        type: '',
        synchro: '-1'
      },
      tableList: [],
      total: 0
    }
  },
  computed: {
    selectChecked() {
      let arr = this.tableList.filter(item => item.checked)
      return arr.map(item => item.goodsId)
    }
  },
  mounted() {
    this.fecthList()
  },
  methods: {
    jumpUrl(path) {
      window.open(path)
    },
    async handleGoods(goodsId) {
      await this.$repeatedConfirm()
      let [err, res] = await authGoodsChange({ goodsId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.fecthList()
    },
    // 添加
    handleSyncChro(item) {
      this.$router.push({
        path: '/goodsEdit',
        query: {
          goodsId: item.goodsId,
          isAuthGoods: 1
        }
      })
    },
    // 撤回
    async handleSyncChroRecal(item) {
      await this.$repeatedConfirm()
      let [err, res] = await authGoodsDown(item)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      // console.log(res)
      item.synchro = 0
      this.getAuthGoodsCount()
    },
    // 批量撤回
    async handleBatchRecall() {
      await this.$repeatedConfirm()
      let [err, res] = await authGoodsDownAll({ params: this.selectChecked })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取订单列表失败')
      }
      this.fecthList()
    },
    async fecthList() {
      let [err, res] = await authGoodsPage(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取订单列表失败')
      }
      // console.log(res)
      this.total = res.data.total
      this.tableList = res.data.records.map(item => {
        return {
          ...item,
          checked: false
        }
      })
      // console.log(this.tableList)
      this.getAuthGoodsCount()
    },
    async getAuthGoodsCount() {
      let [err, res] = await authGoodsCount()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取数量失败')
      }
      this.tabProcess = res.data
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-tag {
  color: black;
}
.pageCo {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.spu-name {
  font-size: 13px;
}

.spec-info {
  margin-top: 10px;
  font-size: 12px;
  color: #7b7b7b;
}

.upload_wrapper {
  display: inline-block;
  margin-left: 10px;
}
.sort_wrap {
  display: flex;
  align-items: center;
  .sort_item {
    margin-right: 10px;
    line-height: 32px;
    .text {
      margin-right: 10px;
    }
    .price_input {
      padding: 0 10px;
      width: 90px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      border: 1px solid #bbb;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
    }
  }
}

.good_card_wrap {
  display: flex;
  flex-wrap: wrap;
  .good_card {
    padding: 10px;
    margin: 10px;
    width: 200px;
    // height: 320px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    background: #fff;
    box-sizing: border-box;
    .card_title {
      display: flex;
      justify-content: space-between;
    }
    .card_image {
      margin-top: 10px;
      cursor: pointer;
      img {
        width: 180px;
        height: 180px;
      }
    }
    .card_content {
      margin-top: 10px;
      line-height: 20px;
    }
    .card_price {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      color: #ff0000;
      .reduction {
        color: #409eff;
        cursor: pointer;
      }
      .delete {
        cursor: pointer;
      }
    }
    .card_business_name {
      margin-top: 10px;
      line-height: 1.2;
      color: orange;
    }
  }
}
</style>
